.carousel-item {
    picture img {
        z-index:0;
    }
    &::before {
        content:"";
        display: block;
        top:0;
        left:0;
        right:0;
        bottom:0;
        height: 100%;
        width: 100%;
        background-color: #96938E;
        opacity: 0.5;
        mix-blend-mode: multiply;
        z-index: 1;
        position: absolute;
    }
    .carousel-caption {
        z-index: 2;
    }
}