footer {
    .footer-bg {
        max-width: none;
        height: 100%;
        width: 100%;
    }
    .logo {
        display: inline-block;    
        max-width: min(35vw, 125px);
        height: 32px;
        padding: 0;
        margin: 0.5rem 0;
        color:white;
    }
    @include media-breakpoint-up(md) {
        .footer-contact {
            border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
        }
    }
    @include media-breakpoint-down(md) {
        .footer-contact {
            border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
            padding-top: 3em;
            margin-top: 3em;
        }
    }
}