.quicklinks-wrap {
    --negative-margin-height: 7em;
    .spacer {
        height: var(--negative-margin-height);
    }
    .nav-block {
        // Prevent (negative) margin collapsing at top
        padding-top: 0.1px;
        padding-bottom: calc( var(--negative-margin-height) * 0.6 );
    }
    .negative-margin {
        margin-top: calc( var(--negative-margin-height) * -1 );
    }
    ul {
        list-style: none;
        padding: 0;
        li {
            a {
                background-color: #96938E;
                transition: background-color .3s ease-in-out;
                display: block;
                height: 100%;
                aspect-ratio: 1/1;
                img {
                    width:100%;
                    height: 100%;
                    mix-blend-mode: multiply;
                    object-fit: cover;
                }
                &:hover{
                    background-color: white;
                }
            }
        }
        .title {
            position: absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            text-align: center;
        }
    }
}