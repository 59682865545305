// Customise the menu a bit - include a box that goes all the way to the right of the 
// submenu to the edge of the window.
header {
    .navbar-brand{
        max-width: min(35vw, 125px);
        height: 32px;
        padding:0;
        margin: 1rem 0;
    }
    .navbar {
        padding: 0 !important;
    }
    .dropdown-toggle::after {
        background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' version='2.0' viewBox='0 0 16 16' fill='black'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
        width: 16px;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        border: none;
    }
    .nav-link.active {
        font-weight: $font-weight-bold;
        color: var(--bs-secondary) !important;
    }
    @include media-breakpoint-up(lg) {         
        .navbar-collapse {
            align-self: stretch;
            .navbar-nav {
                align-self: stretch;
            }
            .nav-item {
                align-self: stretch;
                color: var(--bs-body-colour); 
                .btn-group {
                    height: 100%;                
                }
                .nav-link { 
                    display: flex;
                    align-items: center;
                    height: 100%;
                    color: inherit;
                }
            }
            .btn-group:has(.dropdown-toggle.show) {
                border-radius:0;
                border-width: 0;
                background-color: $secondary-bg-subtle;
                color: var(--bs-secondary-color);
            }
        }
        .dropdown-menu {
            margin-top:0 !important;
            border-radius:0;
            border-width: 0;
            --bs-dropdown-bg: #{$secondary-bg-subtle};
            color: var(--bs-secondary-color);
            .nav-link {
                color: inherit;
            }
            &.show::after {
                content: "";
                display: block;
                position: absolute;
                left: 100%;
                width: 100vw;
                top: 0;
                bottom: 0;
                background: var(--bs-dropdown-bg);
            }
        }
    }
}