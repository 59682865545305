.year {
    .head svg {
        width: 1em;
        height: 1em;
    }
    .head.collapsed svg {   
        transform: rotate(-90deg);
    }
    .data-row{
        td {
            border-bottom:1px solid #B2CFD6;
        }
    }
    td, th {
        @include media-breakpoint-down(lg) {
            font-size: rfs-fluid-value(0.825rem);
        }
        @include media-breakpoint-down(sm) {
            font-size: rfs-fluid-value(0.75rem);
        }    
    }
}