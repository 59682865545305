.page-header {
    picture.page-header-background::before {
        content:"";
        display: block;
        top:0;
        left:0;
        right:0;
        bottom:0;
        height: 100%;
        width: 100%;
        background-color: #96938E;
        opacity: 0.5;
        mix-blend-mode: multiply;
        position: absolute;
        z-index: 1;
    }
    &.has-graphic {
        min-height: 30vw;
    }
}
.page-header .page-title, 
#banners-home .banner-title {
    @include media-breakpoint-up(xxl) { 
        font-size: 4vw; // client request https://usepastel.com/link/oyvy8rro/comment/5093905#/about/, https://usepastel.com/link/oyvy8rro/comment/5093899#/
    }
}
/* Limit height of splash container. 
    NB: We have added a .splash-wrap class to the splash wrapper to make it easier to target this in future.
*/
.site-body > .min-vh-100 > .vh-100 {
    max-height: 80vh;
}