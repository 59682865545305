// For complete list of CSS variables (bootstrap 5.3)
// https://getbootstrap.com/docs/5.3/customize/css-variables/

// For complete list of SCSS variables
// node_modules/bootstrap/scss/_variables.scss


// Theme colors
$primary: #003893;
$secondary: #B2CFD6;
$secondary-bg-subtle: #DAEEF3;
// For other theme color
// refer to https://getbootstrap.com/docs/5.3/customize/sass/ for more details
// $success:       $green ;
// $info:          $cyan ;
// $warning:       $yellow ;
// $danger:        $red lt;
// $light:         $gray-100 !default;
// $dark:          $gray-900 !default;

// Fonts
$primary-text-emphasis: #003893;
$body-emphasis-color: #003893;
$headings-color: $primary;

$font-family-base: 'Arimo', Arial, Helvetica, sans-serif;
$font-size-base: 1rem;
$headings-font-weight: 600;

// Links
$link-color: #26658d;
$link-decoration: none;

// Set table striping colour
$table-bg: #00245E;
$table-color: white;
$table-striped-bg:#000B44;
$table-border-width: 0;

//modal tweaks
$modal-backdrop-bg: $secondary;
$modal-content-border-radius: 0;

//Form tweaks
$input-bg: inherit;
$input-focus-bg: var(--bs-body-bg);
$input-border-radius: 0;
$input-border-color: #96938E;
